import { Box, Typography, TextField, Button, Grid } from '@mui/material';
import React, { useState } from 'react'
import ReusableFormTextField from '../../components/TextField';
import ReusableButton from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../Dashboard/Sidebar';



const ClientDetails = () => {
     const navigate =useNavigate();
        const [formData, setFormData] = useState({
        user_name: '',
        user_email: '',
        user_phone: '',
        user_job_role: '',
        user_department: '',
        user_company: ''
    });
    const [errors, setErrors] = useState({
        user_name: '',
        user_email: '',
        user_phone: '',
        user_job_role: '',
      
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            switch (name) {
                case 'user_name':
                    if (!value.trim()) {
                        newErrors.user_name = 'Name is required';
                    } else if (/\d/.test(value)) {
                        newErrors.user_name = 'Name should not contain numbers';
                    } else {
                        newErrors.user_name = '';
                    }
                    break;
                case 'user_email':
                    if (!value.trim()) {
                        newErrors.user_email = 'Email is required';
                    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.(com|in)$/.test(value)) {
                        newErrors.user_email = 'Invalid email format';
                    } else {
                        newErrors.user_email = '';
                    }
                    break;
                case 'user_phone':
                    if (!value.trim()) {
                        newErrors.user_phone = 'Phone number is required';
                    } else if (!/^[9876]\d{9}$/.test(value)) {
                        newErrors.user_phone = 'Invalid phone number';
                    } else {
                        newErrors.user_phone = '';
                    }
                    break;
                    case 'user_job_role':
                       
                        if (!value.trim()) {
                            newErrors.user_job_role = 'Job role is required';
                        } else if (/\d/.test(value)) {
                            newErrors.user_job_role = 'Job role cannot contain numbers';
                        } else {
                            newErrors.user_job_role = ''; 
                        }
                        break;
             
                default:
                    break;
            }
            return newErrors;
        });
    };

    const validateForm = () => {
        let newErrors = { ...errors };
        let formIsValid = true;

        if (!formData.user_name.trim()) {
            newErrors.user_name = 'Name is required';
            formIsValid = false;
        } else if (/\d/.test(formData.user_name)) {
            newErrors.user_name = 'Name should not contain numbers';
            formIsValid = false;
        }

        if (!formData.user_email.trim()) {
            newErrors.user_email = 'Email is required';
            formIsValid = false;
        } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.(com|in)$/.test(formData.user_email)) {
            newErrors.user_email = 'Invalid email format';
            formIsValid = false;
        }

        if (!formData.user_phone.trim()) {
            newErrors.user_phone = 'Phone number is required';
            formIsValid = false;
        } else if (!/^[9876]\d{9}$/.test(formData.user_phone)) {
            newErrors.user_phone = 'Invalid phone number';
            formIsValid = false;
        }

        if (!formData.user_job_role.trim()) {
            newErrors.user_job_role = 'Job role is required';
            formIsValid = false;
        } else if (/\d/.test(formData.user_job_role)) {
            newErrors.user_job_role = 'Job role cannot contain numbers';
            formIsValid = false;
        }

        setErrors(newErrors);
        return formIsValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
         
            localStorage.setItem("formData", JSON.stringify(formData));

            // Retrieve formData from localStorage and parse it back into an object
            const storedFormData = JSON.parse(localStorage.getItem("formData"));
            console.log("Form data:", storedFormData);

           navigate("/selectapps")
        }
    };
    
    return (


        <Grid container justifyContent="center">
        <Grid item  md={0.5}>
          <Sidebar />
        </Grid>
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',  

            }}
        >
            <Box
                sx={{
                    width: '800px',
                    padding: '32px',
                    backgroundColor: '#ffffff',
                    borderRadius: '16px',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    textAlign: 'center',
                }}
            >
                <Typography variant="h6" sx={{ fontWeight: '500',fontSize:'24px' , color: '#1A202C', mb: 3 ,  fontFamily: '"Exo 2", sans-serif',}}>
                    Please provide <span style={{ color: 'rgba(20, 4, 44, 1)',fontWeight:'700',fontSize:'28px',  fontFamily: '"Exo 2", sans-serif', }}>App Owner's</span> Information
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <ReusableFormTextField
                            placeholder=" Full Name"
                            name="user_name"
                            customStyles={{ height: "2.5rem", borderRadius: '0.5rem', width: '100%', marginTop: '0',fontSize:'13px',fontWeight:'500'
                             }}
                        value={formData.user_name}
                        onChange={handleInputChange}
                        error={!!errors.user_name}
                        helperText={errors.user_name}
                        />
                    </Grid>
                    <Grid item xs={6}>
                    <ReusableFormTextField
                            placeholder=" Email"
                            name="user_email"
                            customStyles={{ height: "2.5rem", borderRadius: '0.5rem', width: '100%', marginTop: '0',fontSize:'13px',fontWeight:'500' }}
                        value={formData.user_email}
                        onChange={handleInputChange}
                        error={!!errors.user_email}
                        helperText={errors.user_email}
                        />
                       
                    </Grid>
                    <Grid item xs={6}>
                    <ReusableFormTextField
                            placeholder=" Phone"
                            name="user_phone"
                            customStyles={{ height: "2.5rem", borderRadius: '0.5rem', width: '100%', marginTop: '0',fontSize:'13px',fontWeight:'500' }}
                        value={formData.user_phone}
                        onChange={handleInputChange}
                        error={!!errors.user_phone}
                        helperText={errors.user_phone}
                        />
                    </Grid>
                    <Grid item xs={6}>
                    <ReusableFormTextField
                            placeholder=" Company Name (if applicable)"
                            name="user_company"
                            customStyles={{ height: "2.5rem", borderRadius: '0.5rem', width: '100%', marginTop: '0',fontSize:'13px',fontWeight:'500' }}
                        value={formData.user_company}
                        onChange={handleInputChange}
                        // error={!!errors.jobRole}
                        // helperText={errors.jobRole}
                        />
                       
                    </Grid>
                    <Grid item xs={6}>
                    <ReusableFormTextField
                            placeholder=" Job Title/Role"
                            name="user_job_role"
                            customStyles={{ height: "2.5rem", borderRadius: '0.5rem', width: '100%', marginTop: '0',fontSize:'13px',fontWeight:'500' }}
                        value={formData.user_job_role}
                        onChange={handleInputChange}
                        error={!!errors.user_job_role}
                        helperText={errors.user_job_role}
                        />
              
                      
                    </Grid>
                    <Grid item xs={6}>
                    <ReusableFormTextField
                            placeholder=" Department (if applicable)"
                            name="user_department"
                            customStyles={{ height: "2.5rem", borderRadius: '0.5rem', width: '100%', marginTop: '0',fontSize:'13px',fontWeight:'500' }}
                            value={formData.user_department}
                        onChange={handleInputChange}
                        // error={!!errors.jobRole}
                        // helperText={errors.jobRole}
                        />

                       
                    </Grid>
                </Grid>

              
                <ReusableButton

                            sx={{
                                marginTop: '10px',
                                '&:hover': { color: 'white', backgroundColor: 'black' },
                                textTransform: 'none',
                                color: '#14042C',
                                backgroundColor: '#F4D1BE',
                                width: { xs: '100%', sm: '338px' },
                                borderRadius: '20px',
                                padding: '0px',
                                height: "2.5rem",
                                mt:'20px',
                                fontWeight:'600'
                            }}
                            onClick={handleSubmit}
                        >
                            Next
                        </ReusableButton>
            </Box>
        </Box>
        </Grid>
    )
}

export default ClientDetails






