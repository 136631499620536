
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Typography, useTheme } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { Icon } from "@iconify/react";
import Loader from "../../components/Loader";
import SidebarLayout from "../../SidebarLayout/SidebarLayout";
import EmailLoginPage from "../Step2UiScreens/MobileScreens/EmailLoginPage";
import ForgetPassword from "../Step2UiScreens/MobileScreens/ForgetPassword";
import VerifyOtp from "../Step2UiScreens/MobileScreens/VerifyOtp";
import SetNewpassword from "../Step2UiScreens/MobileScreens/SetNewpassword";
import SignupPage from "../Step2UiScreens/MobileScreens/SignupPage";
import ReusableButton from "../../components/Button";
import GradientBorderBox from "../../components/GradientBorderBox";
import BottomBackButton from "../../components/BottomBackButton";
import BottomSelectButton from "../../components/BottomSelectButton";
import { useNavigate } from "react-router-dom";
import HomePage from "../Step2UiScreens/MobileScreens/HomePage";
import ProductsList from "../Step2UiScreens/MobileScreens/ProductsList";
import InnerProduct from "../Step2UiScreens/MobileScreens/InnerProduct";
import EmailLoginScreen from "../Step2UiScreens/DesktopScreens/EmailLoginScreen";
import HomeScreen from "../Step2UiScreens/DesktopScreens/HomeScreen";
import ProductListScreen from "../Step2UiScreens/DesktopScreens/ProductListScreen";
import InnerProductScreen from "../Step2UiScreens/DesktopScreens/InnerProductScreen";
import { useDispatch } from "react-redux";
import { updateCustomAppInitiate } from "../../redux/actions/stepsFlow/updateCustomAppActions";

const AppScreensPage2 = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState("mobile");
  const dispatch = useDispatch();
  let Id = localStorage.getItem("appId");

  // Simulate an async process
  useEffect(() => {
    localStorage.setItem("AppscreenLoader", "false")
  }, []);

  let theme = useTheme();
  const storedData = JSON.parse(sessionStorage.getItem("appData"));

  const handleViewChange = (selectedView) => {
    setView(selectedView);
  };
  const Submit = () => {
    const appData = {
      application_name: storedData.application_name,
      logo: storedData.logo.selectedImage,
      theme_color: storedData.theme_color,
      step: "2",
      ...(localStorage.getItem("user_id") && { user_id: localStorage.getItem("user_id") }),
    };

    dispatch(updateCustomAppInitiate(appData, Id, navigate));

  }
  return (
    <>

      <Box sx={{ display: "flex", height: "100%" }}>
        <CssBaseline />
        <SidebarLayout />
        <Box sx={{ width: '100%', backgroundColor: '#F7F7F7' }}>
          <Grid container sx={{ justifyContent: 'center', position: 'relative', overflow: 'hidden', padding: '20px' }}>
            <Grid item sx={{ width: '98%' }}>
              <Box sx={{ border: '2px solid #C8B9DE', padding: '16px 24px', borderRadius: '20px', mt: '10px' }}>
                <Typography sx={{ textAlign: 'center', color: '#4B5563', fontSize: { xs: '10px', md: '12px', lg: '15px' }, fontFamily: '"Inter", sans-serif', }}>
                  The screens you're viewing are examples meant to illustrate how your app could look once it’s developed. These visuals are based on commonly used layouts and design patterns, giving you an idea of the possible appearance and flow of your app. Remember, these are just examples, and your final app can be tailored to your unique preferences. Use these screens to help envision the potential of your app as you move forward in the creation process.
                </Typography>
              </Box>

              <Grid item sx={{ display: 'flex', justifyContent: 'center', mt: '20px' }}>
                <Box sx={{ border: '1px solid #C8B9DE', padding: '5px', borderRadius: '8px' }}>
                  <Button
                    onClick={() => handleViewChange("mobile")}
                    sx={{
                      textTransform: 'none',
                      color: view === "mobile" ? "#ffffff" : "#000000",
                      backgroundColor: view === "mobile" ? "#5A447A" : "transparent",
                      '&:hover': { backgroundColor: view === "mobile" ? "#5A447A" : "#f0f0f0" },
                      marginRight: '10px',
                    }}
                  >
                    <Icon icon="humbleicons:mobile" width="20px" height="20px" style={{ color: view === "mobile" ? "#ffffff" : "#000000" }} />
                    Mobile View
                  </Button>

                  <Button
                    onClick={() => handleViewChange("desktop")}
                    sx={{
                      textTransform: 'none',
                      color: view === "desktop" ? "#ffffff" : "#000000",
                      backgroundColor: view === "desktop" ? "#5A447A" : "transparent",
                      '&:hover': { backgroundColor: view === "desktop" ? "#5A447A" : "#f0f0f0" },
                    }}
                  >
                    <Icon icon="quill:desktop" width="20px" height="20px" style={{ color: view === "desktop" ? "#ffffff" : "#000000", marginRight: '2px' }} />
                    Desktop View
                  </Button>
                </Box>
              </Grid>

              {/* Conditionally render content based on selected view */}
              <Box
                sx={{
                  mt: '20px',
                  // border: '1px solid black',
                  padding: '10px',
                  borderRadius: '5px',

                }}
              >
                {view === "mobile" ? (
                  <Grid container sx={{ justifyContent: 'center', }}>
                    <Grid container sx={{ width: '90%', }} rowSpacing={3} columnSpacing={2}>
                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <HomePage data={storedData} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <ProductsList data={storedData} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <InnerProduct data={storedData} />
                      </Grid>

                    </Grid>


                  </Grid>
                ) : (
                  <Grid container sx={{ justifyContent: 'center', }}>
                    <Grid container sx={{ width: '100%', }} rowSpacing={3} columnSpacing={2} >
                      <Grid item xs={12} lg={6} >
                        <HomeScreen data={storedData} />
                      </Grid>
                      <Grid item xs={12} lg={6} >
                        <ProductListScreen data={storedData} />
                      </Grid>
                      <Grid item xs={12} lg={6} >
                        <InnerProductScreen data={storedData} />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Grid>
            <Box component="span"
              sx={{
                position: 'absolute',

                top: '20px',
                right: '-35px',
                background: 'radial-gradient(50% 375.88% at 50% 50%, #14042C 0%, #6F5498 67.9%, #C8B9DE 100%)',
                color: '#ffffff',
                width: '250px',
                padding: '8px 0px',
                paddingLeft: '30px',
                margin: 0,
                marginLeft: '10px',
                textAlign: 'center',
                alignItems: 'center',
                fontWeight: 'bold',
                fontSize: '14px',
                transform: 'rotate(35deg)',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                clipPath: 'polygon(20% 0, 91.5% 0, 100% 100%, 0 100%)',

              }}
            >
              Preview Screens
            </Box>
            {/* next screen buttons start */}
             {/* //next click button */}
             <Box
                sx={{
                  position: "fixed",
                  top: "50%",
                  left: view === 'mobile' ? '95%' : '96.2%',
                  zIndex: 1000,
                }}
              >
                <Box
                  onClick={() => navigate("/appscreenspage3")}
                  sx={{

                    backgroundColor: '#C8B9DE',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '25px 12px 20px 12px',
                    borderRadius: '5px',
                    animation: 'pulse 2s infinite, moveHorizontally 3s infinite',
                    '@keyframes pulse': {
                      '0%': { transform: 'scale(1)' },
                      '50%': { transform: 'scale(1.10)' },
                      '100%': { transform: 'scale(1)' },
                    },
                    '@keyframes moveHorizontally': {
                      '0%': { transform: 'translateX(0)' },
                      '50%': { transform: 'translateX(-10px)' },
                      '100%': { transform: 'translateX(0)' },
                    },
                    '&:hover': {
                      animation: 'pulse 3s infinite',

                    },
                    cursor: 'pointer',

                    '&::after': {
                      content: '"Next Screens "',
                      position: 'absolute',
                      bottom: '0px',
                      letterSpacing: '0.5px',
                      width: '80px',
                      transform: 'translateX(-50%)',
                      opacity: 0,
                      transition: 'opacity 0.3s ease-in-out',
                      fontSize: '10px',
                      color: '#333',
                      top: '80px',
                      fontFamily: '"Exo 2", sans-serif',
                    },
                    '&:hover::after': {
                      opacity: 1,
                    },
                  }}
                >
                  <Icon
                    icon="ic:round-play-arrow"
                    width="20px"
                    height="20px"
                    style={{ color: "#14042C" }}
                  />
                </Box>
              </Box>

            {/* back */}

             {/* //next click button */}
             <Box
                sx={{
                  position: "fixed",
                  top: "49%",
                  left:view ==='mobile'?'12%':'12%',
                  zIndex: 1000,
                }}
              >
                <Box
                  onClick={() => navigate("/appscreenspage1")}
                  sx={{

                    backgroundColor: '#C8B9DE',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '25px 14px 20px 14px',
                    borderRadius: '5px',
                    animation: 'pulse 2s infinite, moveHorizontally 3s infinite',
                    '@keyframes pulse': {
                      '0%': { transform: 'scale(1)' },
                      '50%': { transform: 'scale(1.10)' },
                      '100%': { transform: 'scale(1)' },
                    },
                    '@keyframes moveHorizontally': {
                      '0%': { transform: 'translateX(0)' },
                      '50%': { transform: 'translateX(-10px)' },
                      '100%': { transform: 'translateX(0)' },
                    },
                    '&:hover': {
                      animation: 'pulse 3s infinite',

                    },
                    cursor: 'pointer',

                    '&::after': {
                      content: '"Previous Screens "',
                      position: 'absolute',
                      bottom: '0px',
                      letterSpacing: '0.5px',
                      width: '90px',
                      transform: 'translateX(-50%)',
                      opacity: 0,
                      transition: 'opacity 0.3s ease-in-out',
                      fontSize: '10px',
                      color: '#333',
                      top: '80px',
                      fontFamily: '"Exo 2", sans-serif',
                    },
                    '&:hover::after': {
                      opacity: 1,
                    },
                  }}
                >
                      <Icon icon="bxs:left-arrow" width="14px" height="14px"  style={{ color: "#14042C" }} />
                </Box>
              </Box>

            {/* <Box
              sx={{
                position: "fixed",
                top: "50%",
                left:view ==='mobile'?'12%':'11%',
                zIndex: 1000,
              }}
            >
              <Box onClick={() => navigate("/appscreenspage1")} sx={{
                backgroundColor: '#C8B9DE', justifyContent: 'center', alignItems: 'center', padding: '18px 8px', borderRadius: '5px',
                animation: 'pulse 2s infinite',
                '@keyframes pulse': {
                  '0%': { transform: 'scale(1)' },
                  '50%': { transform: 'scale(1.05)' },
                  '100%': { transform: 'scale(1)' },
                },
              }}>
                
              
                <Icon icon="bxs:left-arrow" width="12px" height="12px"  style={{ color: "#14042C" }} />
                
              </Box>
            </Box> */}
          {/* next screen buttons end*/}
          </Grid>
          {/* <Box sx={{display:'flex',position:'sticky'}}>
            <ReusableButton>hghdg</ReusableButton>
            <ReusableButton>hghdg</ReusableButton>

          </Box> */}

          {/* <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              height: "auto",
              position: "sticky",
              bottom: '80px', // Adjust this based on desired offset
              padding: "8px",
              zIndex: 1, //
            }}
          >
            <GradientBorderBox
              sx={{
                marginTop: "10px",
                backgroundColor: "#FFFFFF",
                textAlign: "right",
                width: "100%",
                maxWidth: {
                  xs: "90%", 
                  sm: "60%", 
                  md: "40%", 
                },
                height: "80px",
                borderRadius: "24px",
                boxShadow:
                  "0px 25px 60px -15px rgba(16, 24, 40, 0.2), 0px 25px 60px -15px rgba(16, 24, 40, 0.12)",
              }}
            >
              <BottomBackButton
                onClick={() => navigate("/appscreenspage1")}
                variant="contained"
                sx={{
                  fontSize: { xs: "0.8rem", sm: "1rem" }, // Adjust font size for smaller screens
                  padding: { xs: "6px 12px", sm: "8px 16px" }, // Adjust padding for smaller screens
                  marginRight: "8px",
                  marginLeft: { xs: '10px', lg: 'none' }
                }}
              >
                Go Back
              </BottomBackButton>
              <BottomSelectButton
                onClick={() => navigate("/appscreenspage3")}
                variant="contained"
                sx={{
                  fontSize: { xs: "0.8rem", sm: "1rem" },
                  padding: { xs: "6px 12px", sm: "8px 16px" },
                  marginRight: { xs: '10px', lg: 'none' }
                }}
              >
                View Next Screens
              </BottomSelectButton>
            </GradientBorderBox>
          </Box> */}

          <GradientBorderBox
            sx={{
              marginTop: "20px",
              position: "sticky",
              bottom: 0,
              right: 0,
              marginLeft: "auto",
              backgroundColor: "#FFFFFF",
              maxWidth: '730px',
              height: "auto",
              borderRadius: "24px",
              boxShadow:
                "0px 25px 60px -15px rgba(16, 24, 40, 0.2), 0px 25px 60px -15px rgba(16, 24, 40, 0.12)",
            }}
          >
            <Box
              sx={{
                padding: { xs: "5px", sm: "10px" },
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                gap: { xs: 2, sm: 5 },
              }}
            >
              <img
                src={storedData.logo.selectedImage || "./assets/images/defaultLogo.png"}
                alt="Logo"
                style={{
                  width: "45px",
                  height: "40px",
                }}
              />
              <Typography
                sx={{
                  fontFamily: '"Exo 2", sans-serif',
                  fontSize: { xs: "1.2rem", sm: "1.5rem" },
                  fontWeight: 600,
                  lineHeight: "2rem",
                  color: "#000000",
                }}
              >
                {storedData.application_name}
              </Typography>

              <BottomBackButton
                onClick={() => navigate("/appscreenspage1")}
                variant="contained"
                sx={{
                  fontSize: { xs: "0.75rem", sm: "1rem" },
                  padding: { xs: "5px 10px", sm: "10px 20px" },
                }}
              >
                Back
              </BottomBackButton>
              <BottomSelectButton
                onClick={() => Submit()}
                variant="contained"
                sx={{
                  fontSize: { xs: "0.75rem", sm: "1rem" },
                  padding: { xs: "5px 10px", sm: "10px 20px" },
                }}
              >
                Continue to Next Step
              </BottomSelectButton>
            </Box>
          </GradientBorderBox>


        </Box>
      </Box>

    </>
  )
}

export default AppScreensPage2
