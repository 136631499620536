
import React, { lazy, Suspense, useEffect, useRef} from "react";
import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import Loader from "./components/Loader";
import AppScreensPage2 from "./pages/AppCustomization/AppScreensPage2";
import AppScreensPage1 from "./pages/AppCustomization/AppScreensPage1";
import AppScreensPage3 from "./pages/AppCustomization/AppScreensPage3";
import ClientDetails from "./pages/Profile/ClientDetails";
import { createGlobalStyle } from "styled-components";

const AppReview = lazy(() => import("./pages/AppReview/AppReview"));
const Payments = lazy(() => import("./pages/Payments/Payments"));
const AdditionalServices = lazy(
  () => import("./pages/Sevrices/AdditionalServices")
);
const AdditionalInfo = lazy(
  () => import("./pages/AdditionalInfo/AdditionalInfo")
);
const PlatformDeliveryDetails = lazy(
  () => import("./pages/PlatformDelivery/PlatformDeliveryDetails")
);
const FormPage = lazy(() => import("./pages/Form/FormPage"));
const AppFeature = lazy(() => import("./pages/AppFeatures/AppFeature"));
const SelectApps = lazy(() => import("./pages/SelectApps/SelectApps"));
const UserNameFormPage = lazy(
  () => import("./pages/UserDetailsForms/UserNameFormPage")
);
const LandingPage = lazy(() => import("./pages/HomePage/LandingPage"));
const AppCustomization = lazy(
  () => import("./pages/AppCustomization/AppCustomization")
);
const Flow = lazy(() => import("./pages/AppCustomization/AppScreensPage1"));
const HomePage = lazy(() => import("./pages/HomePage/HomePage"));
const LoginRoutepage=lazy(()=>import("./pages/Login&Signup/LoginRouteComponent"))

const Dashbaord =lazy(()=>import("./pages/Dashboard/Dashboardpage"));





const GlobalStyle = createGlobalStyle`
  html, body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
  }
`;



const ScrollToTop = () => {
  const pathname = useLocation();
  const prevPathname = useRef();
 
  useEffect(() => {
    if (prevPathname.current !== pathname) {
      window.scrollTo(0, 0);
      prevPathname.current = pathname;
    }
  }, [pathname]);
 
  return null;
};

const AppRoutes = () => {
    return (
      <>
        {/* <Suspense fallback={<div>{"Loading ..."}</div>}> */}
        {/* <Suspense fallback={<Loader />}> */}
     
        
        <Suspense >
        <ScrollToTop/>
          <Routes>
            <Route path="/" exact element={<LandingPage />} />
            <Route
              path="/appcustomization"
              exact
              element={<AppCustomization />}
            />
            <Route path="/appscreenspage1" exact element={<AppScreensPage1 />} />
            <Route path="/appscreenspage2" exact element={<AppScreensPage2 />} />
            <Route path="/appscreenspage3" exact element={<AppScreensPage3 />} />
            <Route path="/home" exact element={<HomePage />} />
            <Route path="/usernameform" exact element={<UserNameFormPage />} />
            <Route path="/selectapps" exact element={<SelectApps />} />
            <Route path="/appfeature" exact element={<AppFeature />} />
            <Route path="/agency/clientdetails" element={<ClientDetails />} />
            <Route
              path="/platformdelivery"
              exact
              element={<PlatformDeliveryDetails />}
            />
            <Route path="/additionalinfo" exact element={<AdditionalInfo />} />
            <Route path="/payments" exact element={<Payments />} />
            <Route path="/services" exact element={<AdditionalServices />} />
            <Route path="/appreview" exact element={<AppReview />} />
            {/* //sivakumar */}
            <Route path="/dashboard/*" exact element={<Dashbaord/>}/>
            {/* //manikanta */}
            <Route  path="/signinpage/*" exact element={<LoginRoutepage/>} />

            {/* agent module */}
            <Route  path="/agency/signinpage/*" exact element={<LoginRoutepage/>} />

            <Route path="/formpage" exact element={<FormPage />} />

              {/* new */}

            <Route path="/agency/dashboard/*" exact element={<Dashbaord/>}/>
          </Routes>
        </Suspense>
       
      </>
    );
};

export default AppRoutes;