import { Box, Button, Grid, lighten, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react'

import { Icon } from "@iconify/react";
import ReusableFormTextField from '../../../components/TextField';
import TopNotch from './TopNotch';
import BottomNotch from './BottomNotch';
import { ToastContainer,toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

const SetNewpassword = (data) => {
    const location =useLocation();
    const storedAppData = data.data;
    const colorGradient = storedAppData.theme_color;
    const colorMatch = colorGradient.match(/#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/);
    const extractedColor = colorMatch ? colorMatch[0] : null; 
    useEffect(() => {
      if (location.state?.successMessage) {
          toast.success(location.state.successMessage);
          window.history.replaceState({}, document.title);
        }
      }, [location.state]);

    return (
        <>

            <Grid container

                sx={{
                    height: '640px',
                    background: `linear-gradient(to bottom, white 70%, ${extractedColor} 110%)`,
                    border: '10px solid grey',
                    borderRadius: '20px',
                    width: '300px',
                    padding: '7px',
                    position: 'relative',
                    alignItems:'center',


                }}
            >
                <TopNotch />
                <Box sx={{ top: '40px', left: '10px', position: 'absolute' }}>
                    <Icon icon="line-md:arrow-left" width="20px" height="20px" style={{ color: '#14042C' }} />
                </Box>


                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: '20px',
                        width: '100%',
                        mb: '90px'

                    }}
                >
                    {/* App Logo */}
                    <Box >
                        <img
                            src={storedAppData && storedAppData.logo && storedAppData.logo.selectedImage
                                ? storedAppData.logo.selectedImage
                                : "../assets/images/defaultLogo.png"}

                            style={{ width: '64px', height: '64px' }}
                        />
                    </Box>

                    {/* App Name */}
                    <Typography variant="h5" sx={{ mb: 4, fontFamily: '"Inter", sans-serif', }}>
                        {storedAppData.application_name}
                    </Typography>

                    <Grid
                        container
                        spacing={2}
                        sx={{
                            border: `1px solid ${lighten(extractedColor, 0.5)}`,
                            padding: '10px',
                            borderRadius: '16px',
                            textAlign: 'center',
                            width: '100%',
                            ml: '1px'
                        }}
                    >
                        <Grid item sx={{ justifyContent: 'center' }}>
                            <Box sx={{ mb: '8px' }}>
                                <Typography sx={{ fontWeight: '500', fontSize: '18px', fontFamily: '"Inter", sans-serif', }}>Set new Password</Typography>
                                <Typography sx={{ fontSize: '10px', fontFamily: '"Inter", sans-serif', }}>
                                    Enter new password
                                    & re-enter new password
                                </Typography>
                            </Box>
                        </Grid>

                        {/* Email and Password Input */}


                        <Box sx={{ height: '30px', border: '1px solid black', padding: '3px 6px', width: '100%', borderRadius: '8px', textAlign: 'start', borderColor: `${lighten(extractedColor, 0.5)}`, alignContent: 'center' }}>
                            <Typography sx={{ color: 'grey', fontSize: '12px', fontWeight: '500' }} >New Password</Typography>
                        </Box>

                        <Box sx={{ height: '30px', border: '1px solid black', padding: '3px 6px', width: '100%', borderRadius: '8px', textAlign: 'start', borderColor: `${lighten(extractedColor, 0.5)}`, alignContent: 'center', mt: '5px' }}>
                            <Typography sx={{ color: 'grey', fontSize: '12px', fontWeight: '500' }} >Re-enter new Password</Typography>
                        </Box>
                        <Button
                            variant="contained"
                            fullWidth
                            sx={{
                                mb: 1,
                                backgroundColor: `${lighten(extractedColor, 0.5)}`,
                                '&:hover': { backgroundColor: `${lighten(extractedColor, 0.5)}` },
                                textTransform: 'none',
                                color: '#4B5563',
                                fontWeight: '600',
                                fontSize: '12px',
                                fontFamily: '"Inter", sans-serif',
                                borderRadius: '8px',
                                mt: '10px'
                            }}
                        >
                            Update Password
                        </Button>
                    </Grid>
                </Box>
                <BottomNotch/>

            </Grid>
            <ToastContainer/>
        </>
    )
}

export default SetNewpassword
